<template>
    <div class="preview-layer" v-if="visible" @click="closePreviewModal" style="overflow:scroll">
        <div class="preview-view" @click.stop style="position: relative;left:0;top:50px;transform:none">
            <RealPhonePreview>
                <template v-slot:preview>
                    <div class="visiting-detail-page">
                        <div
                            class="visiting-detail-photos"
                            v-if="visitingService && visitingService.photos"
                        >
                            <el-carousel height="220px">
                                <el-carousel-item
                                    v-for="(
                                        item, imgN
                                    ) in visitingService.photos"
                                    :key="imgN"
                                >
                                    <img :src="item" alt="" />
                                </el-carousel-item>
                            </el-carousel>
                        </div>

                        <div class="visiting-service-info">
                            <div class="visiting-service-info-item">
                                <p>{{visitingService.itemsName || visitingService.name}}</p>
                            </div>
                            <div class="visiting-service-info-item">
                                <p>
                                    <img
                                        src="../../assets/meeting-room/meeting-room-address.png"
                                    />
                                    {{visitingService.serviceLocation}}
                                </p>
                            </div>
                            <div class="visiting-service-info-item">
                                <div>
                                    <div style="display: flex;">
                                        <img style="margin-top: 3px;" src="../../assets/meeting-room/time-scope.png"/>
                                        <div style="margin-right: 10px">
                                            服务日期：
                                        </div>
                                        <div v-if="visitingService.serviceDateType == 1" style="display:flex;flex-flow: column;align-items:center;">
                                            <p v-for="(date, n) in visitingService.serviceDate" :key="n">
                                                {{weekMapping[date.week]}} {{date.startTime}}-{{date.endTime}}
                                            </p>
                                        </div>
                                        <div v-else style="display:flex;flex-flow: column;align-items:center;">
                                            <p v-for="(date, n) in visitingService.serviceDate"
                                                :key="n">
                                                {{date.date}} {{date.startTime}}-{{date.endTime}}
                                            </p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>

                        <div style="background: #fff">
                            <div class="rule-info">
                                <div class="rule-info-title">购买须知</div>
                                <div class="rule-info-content">
                                    <p>
                                        购买后，请按服务时间到服务地点消费使用。
                                    </p>
                                </div>
                            </div>
                            <div class="rule-info">
                                <div class="rule-info-title">退款条件</div>
                                <div class="rule-info-content">
                                    <p>1.未使用，可随时退款。</p>
                                    <p>
                                        2.自购买之日起，超过60天未使用，系统自动退款。
                                    </p>
                                </div>
                            </div>
                        </div>
                        <div class="rich-text">
                            <div v-html="visitingService.introduction"></div>
                        </div>

                        <div class="visiting-detail-page-footer">
                            <div class="visiting-detail-page-footer-phone">
                                <img
                                    src="../../assets/meeting-room/phone.png"
                                />
                                商户
                            </div>
                            <button>
                                ¥{{ visitingService.price }}/次 立即购买
                            </button>
                        </div>
                    </div>
                </template>
            </RealPhonePreview>
            <slot name="btnGroup"></slot>
        </div>
    </div>
</template>

<script>
import RealPhonePreview from "@/components/RealPhonePreview";
export default {
    name: "VisitingServicePreview",
    components: {
      RealPhonePreview,
    },
    props: {
        visitingService: Object,
        visible: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            weekMapping: {
                1: "周一",
                2: "周二",
                3: "周三",
                4: "周四",
                5: "周五",
                6: "周六",
                7: "周日",
            },
        };
    },
    methods: {
        closePreviewModal() {
            this.$emit("closeModal");
        },
    },
};
</script>

<style lang="stylus" scoped>
.visiting-detail-page
    width 100%
    // height 100%
    background #F0F0F0
    position relative
    // padding-bottom 32px
    .visiting-detail-photos
        width 100%
        height 220px
        img
            width 100%
            height 100%
    .visiting-service-info
        margin-top 8px
        background #ffffff
        &-item
            padding 12px 14px
            font-size 14px
            .tag
                background #FFF0F0
                color #FF5A5A
                font-size 9.6px
                width 32px
                height 16px
                border-radius 2.5px
                float right
            img
                width 14px
                height 14px
                margin-right 8px
            p
                margin-bottom 0
            .tips
                color #999
                margin-top 7.2px
                font-size 12px
                text-indent 22px
            &:not(:last-of-type)
                border-bottom 1px solid #f0f0f0
    .rule-info
        background #ffffff
        margin-top 8px
        padding 12px 14px
        &-title
            font-size 14px
            position relative
            text-indent 8px
            margin-bottom 8px
            &::before
                position absolute
                left 0
                top 50%
                transform translateY(-50%)
                content ' '
                width 3.5px
                height 10px
                background #1DAFFF
        &-content
            font-size 12px
            p
                margin-bottom 2px
                line-height 16px
    .rich-text
        background #ffffff
        padding 10px
        margin-top 8px
    &-footer
        display flex
        flex-flow row nowrap
        align-items center
        position sticky
        bottom 0
        left 0
        width 100%
        cursor pointer
        box-shadow 3px -3px 4px 0px rgba(0, 0, 0, 0.1)
        height 32px
        &-phone
            width 120px
            background #fff
            display flex
            flex-flow row nowrap
            align-items center
            font-size 14.4px
            height 100%
            justify-content center
            img
                width 11px
                margin-right 2px
        button
            flex 1 1 auto
            background #FF5A5A
            height 100%
            color #fff
            font-size 14.4px
            text-align center
            border none
</style>
